@import "~antd/dist/antd.css";

* {
  padding: 0;
  margin: 0;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #fff;
}

.logo {
  height: 35px;
  margin: 16px;
  background: #39bdc8;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.layout-sider {
  overflow: auto;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  box-shadow: 1px 0 2px 2px rgb(0 0 0 / 20%);
  z-index: 1;
}

.ant-layout-sider {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  /* background: #fff; */
}

.site-layout {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
}

.site-layout-background {
  background: rgb(1, 173, 181);
  padding: 0;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 67px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

.site-layout .site-layout-content {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  background: #fff;
}

.ant-layout-footer {
  padding: 5px 50px 21px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: rgb(1, 173, 181);
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-color: rgb(1, 173, 181);
  position: fixed;
  z-index: 3;
  text-align: center;
  height: 15px;
  box-shadow: 0 -1px 4px rgb(0 0 0 / 20%);
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff;
}

.login-container {
  max-width: 500px;
  height: 100vh;
  text-align: center;
  padding: 150px 0;
  margin: 0 auto;
}

.login-card {
  max-width: 500px;
  padding: 30px;
}

.login-form {
  max-width: 300px;
  margin: 0 auto;
}

.login-form-button {
  width: 100%;
}

.ant-form-item-explain-error {
  text-align: left;
}

.ant-upload.ant-upload-drag {
  margin-bottom: 10px;
}

.collection-create-form_last-form-item {
  margin-bottom: 0;
}

.ant-spin-dot-item {
  background-color: rgb(1, 173, 181);
}